import React from "react";
import { Container } from "react-bootstrap";

const About = () => {
    return (
        <Container fluid className="white-text pb-5 px-5">
            <h1 className="pb-3 display-1 text-center">About AuroraMAX</h1>
            <div className="row justify-content-center pb-3">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-9 col-xxl-8">
                    <p className="white-text">
                        AuroraMAX is a Canadian aurora observatory. By means of a <strong>ground
                            camera</strong> in Yellowknife, Northwest Territories, you can see the
                        aurora from the comfort of your home. The camera turns on automatically <strong>as
                            soon as the Sun sets </strong> in Yellowknife during the aurora season. The
                        countdown timer shows how much time is left until the broadcast starts.
                    </p>
                    <p className="white-text">
                        In addition to the camera, AuroraMAX is also a public outreach initiative that
                        aims to raise awareness of:

                        <ul>
                            <li>the northern lights and their beauty;</li>
                            <li>the science behind the auroras;</li>
                            <li>how solar activity can affect our daily lives.</li>
                        </ul>
                    </p>
                    <p className="white-text">
                        The AuroraMAX project is led by the University of Calgary, and supported by our
                        fantastic partners, the Canadian Space Agency, Astronomy North, and the City of Yellowknife.
                    </p>
                    <p className="white-text">
                        The AuroraMAX logo, designed by James Pugsley of Astronomy North, symbolizes
                        the cause-and-effect relationship between our star and our planet. A prominent
                        green spike represents the changing nature of the aurora, set against the powerful
                        source behind the solar wind—the Sun.
                    </p>
                </div>
            </div>
        </Container>
    );
};

export default About;
