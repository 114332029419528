import React from "react";
import { Container } from "react-bootstrap";

const ContactUs = () => {
    return (
        <Container fluid className="white-text px-5 pb-5">
            <h1 className="pb-3 display-1 text-center">Contact Us</h1>
            <div className="row justify-content-center pb-3">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-9 col-xxl-8">
                    <p className="pt-3 text-center">
                        Feel free to contact us by sending an email to <a className="custom-colored-link" href='&#109;ailt&#111;&#58;info&#64;&#97;%75%72&#111;ra&#109;a&#120;%2&#69;c%6F&#37;&#54;&#68;'>info&#64;&#97;uro&#114;amax&#46;com</a>. We'll get back to you as soon as we can.
                    </p>
                </div>
            </div>
        </Container>
    );
};

export default ContactUs;
