import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/pro-solid-svg-icons";

export const TopicWhatAreNorthernLights = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning"><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
            <h1 className="display-2 pb-3">
                What are the northern lights?
            </h1>
            <p>
                The <b>northern lights</b>, or aurora <b>borealis</b>, are a spectacular, colourful display of light commonly seen in
                the night sky in the northern hemisphere. Auroras in the southern hemisphere are known as the <b>southern lights</b>, or aurora <b>australis</b>.
            </p>
            <p>
                Both the northern lights and the southern lights are <b>polar lights</b>, or aurora <b>polaris</b>, because they occur near Earth's magnetic poles.
            </p>
            <Row>
                <Col md={9}>
                    <img src="/img/learning/colours-of-northern-lights.jpg" alt="colourful northern lights" width="100%" className="rounded" />
                    <figcaption>Aurora borealis over Yellowknife, NWT. (Credits: Canadian Space Agency, University of Calgary, Astronomy North)</figcaption>
                </Col>
            </Row>
            <h2 className="display-4">How auroras form</h2>
            <p>
                Auroras occur when <b>charged particles</b> (electrions and protons) <b>collide</b> with <b>gases</b> in Earth's <b>upper atmosphere</b>.
            </p>
            <p>
                Those collisions produce <b>tiny flashes</b> that fill the sky with colourful light. As billions of flashes occur in sequence, the
                auroras appear to move or "dance" in the sky.
            </p>
            <p>
                Earth's <b>magnetic field</b> steers the charged particles towards the <b>poles</b>. The shape of Earth's magnetic field creates two auroral
                ovals above the North and South Magnetic Poles. That is why auroras occur almost every night in the northern sky, from August to May.
            </p>
            <h2 className="display-4">Solar wind</h2>
            <p>
                Our planet's <b>magnetic field</b> forms an <b>invisible shield</b> that protects us from
                the <a href="https://www.asc-csa.gc.ca/eng/sciences/solar-storms.asp" target="blank" rel="noreferrer">solar wind.</a> From time to time, the solar wind gets stronger and
                penetrates Earth's magnetic field. The stream of particles interacts with gases in the magnetic filed (magnetosphere), generating
                magnificent auroras.
            </p>
            <p>
                As a rule, the more active the Sun is:
            </p>
            <ul>
                <li>
                    the more stunning the auroras will apear on Earth
                </li>
                <li>
                    the further south that the oval positioned about northern Canada will stretch
                </li>
            </ul>
            <p>
                When solar activity decreases, the oval returns to its normal position and the auroras become less intense.
            </p>
            <Row>
                <Col md={9}>
                    <img src="/img/learning/auroral-shield.jpg" alt="auroral shield" className="pb-4 rounded" width="100%" />
                </Col>
            </Row>
            <p>
                Also, because of the solar wind, sometimes the magnetic field lines reconnect on the side of Earth opposite the Sun. They snap back
                like an elastic band, sending large amounts of energy back towars Earth's <b>poles</b>. This phenomenon, called <b>magnetic reconnection</b>,
                creates stunning displays of aurora.
            </p>
            <video controls className="rounded">
                <source src="/img/auroramax_video.mp4"></source>
            </video>
            <figcaption>
                A coronal mass ejection (CME) is an enormous expulsion of plasma and magnetic field from the Sun's outermost atmosphere. This animation traces the path of a CME as it leaves the Sun and slams into Earth’s magnetosphere. The magnetic field lines reconnect behind the planet and produce the polar lights. (Credits: ESA, NASA)
            </figcaption>
            <h2 className="display-4">A colourful phenomenon</h2>
            <p>
                Green, red, blue: the northern lights come in a variety of colours.
            </p>
            <p>
                Go to the <a href="/learning/colours-of-northern-lights">colours of the northern lights</a> page to find out more.
            </p>
            <br />
            <h3 className="display-6">Explore Further</h3>
            <ul>
                <li>
                    <a href="/learning/photographing-aurora">Photographing the aurora</a>
                </li>
                <li>
                    <a href="/learning/aurora-viewing-tips">Aurora viewing tips</a>
                </li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
        </Container>
    );
};


export const TopicColoursOfNorthernLights = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
            <h1 className="display-2 pb-3">
                The colours of the northern lights
            </h1>
            <p>
                The colours of the northern lights are determined by a number of factors:
            </p>
            <ul>
                <li>the composition of gases in Earth's atmosphere</li>
                <li>the altitude where the aurora occurs</li>
                <li>the density of the atmosphere</li>
                <li>the level of energy involved</li>
            </ul>
            <p>
                The nothern lights can be spectacular!
            </p>
            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/colours-of-northern-lights-pink.jpg" alt="northern lights with pink hues" width="100%" className="rounded" />
                        <figcaption>The bright pink fringe on the lower edge of the aurora occurs when electrons strike nitrogen in the atmosphere.
                            (Credits: Canadian Space Agency, University of Calgary, Astronomy North)</figcaption>
                    </figure>
                </Col>
            </Row>
            <p>
                The following colours can be seen when the northern lights are in the sky:
            </p>
            <p><b>Green</b>: Green is the most common colour seen from the ground and is produced when charged particles collide
                with <b>oxygen</b> molecules at altitudes of 100 to 300 km.
            </p>
            <p><b>Pink and dark red</b>: Occasionally, the lower edge of an aurora will have a pink or dark red fringe, which is produced
                by <b>nitrogen</b> molecules at altitudes of around 100 km.
            </p>
            <p><b>Red</b>: A bit higher in the atmosphere (at altitudes of 300 to 400 km), collisions with <b>oxygen</b> atoms produce
                red auroras.
            </p>
            <p>
                <b>Blue and purple</b>: Finally, <b>hydrogen</b> and <b>helium</b> molecules can produce blue and purple auroras, but
                these colours tend to be difficult for our eyes to see against the night sky.
            </p>
            <br />
            <h3 className="display-6">Explore Further</h3>
            <ul>
                <li>
                    <a href="/learning/aurora-viewing-tips">Aurora viewing tips</a>
                </li>
                <li>
                    <a href="/learning/what-are-northern-lights">What are the northern lights?</a>
                </li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
        </Container>
    );
};

export const TopicAuroraMAXObservatory = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
            <h1 className="display-2 pb-3">
                AuroraMAX observatory
            </h1>
            <p>
                AuroraMAX makes it possible to watch the northern lights in <b>high definition</b>, broadcast <b>live</b> from the Canadian North,
                during aurora season, between August and May.<br />
                The camera turns on automatically <b>as soon as the Sun sets</b> in Yellowknife during the aurora season. The countdown timer shows
                how much time is left until the broadcast starts.

            </p>
            <a href="/live" className="learning-button btn btn-outline-light btn-sm me-2 mb-2">Go to the AuroraMAX live feed</a>
            <a href="/movies" className="learning-button btn btn-outline-light btn-sm">Go to AuroraMAX movie archives</a>

            <h2 className="display-4">Why are AuroraMAX images round?</h2>

            <p>
                Imagine that you're lying on your back and you can see everything around you. That is what AuroraMAX shows you.
            </p>
            <p>
                AuroraMAX's main camera is an all-sky imager. It shoots a 180-degree field of view, which produces circular images
                that show the entire night sky. The circumference of the circle is actually the horizon all around the camera. If
                you look carefully, you will see the tree tops even in the upper portion of the image.
            </p>
            <h2 className="display-4">AuroraMAX: more than just a camera!</h2>
            <p>
                AuroraMAX is a public outreach initiative that aims to raise awareness of:
            </p>
            <ul>
                <li>the northern lights and their beauty</li>
                <li>the science behind the auroras</li>
                <li>how solar activity can affect our daily lives</li>
            </ul>
            <p>
                The AuroraMAX project is led by the University of Calgary, and supported by our
                fantastic partners, the Canadian Space Agency, Astronomy North, and the City of Yellowknife.
            </p>
            <p>
                The AuroraMAX logo, designed by James Pugsley of Astronomy North, symbolizes the cause-and-effect relationship between our star and our
                planet. A prominent green spike represents the changing nature of the aurora, set against the powerful source behind the solar wind—the Sun.
            </p>
            <h2 className="display-4 pt-3 pb-3">Technical Specifications</h2>
            <ul style={{ textAlign: "left", display: "inline-block" }}>
                <li>Location: Yellowknife, NWT</li>
                <li>Longitude: 114°21'W</li>
                <li>Latitude: 62°26'N</li>
                <li>Camera: Colour all-sky imager</li>
                <li>Lens: 180° fisheye</li>
                <li>Aperture: f/4.0</li>
                <li>Exposure time: 4 seconds</li>
                <li>Imaging framerate: 6 seconds</li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
        </Container>
    );
};

export const TopicAuroraViewingTips = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
            <h1 className="display-2 pb-3">
                Aurora viewing tips
            </h1>
            <p>
                You don't need any special equipment to see auroras, just a healthy dose of enthusiasm!:
            </p>

            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/viewing-tips.jpg" alt="aurora display" width="100%" className="rounded" />
                        <figcaption>Canada's northern communities are treated to regular displays of the aurora borealis because they are located under the
                            auroral oval. (Credits: Canadian Space Agency, University of Calgary, Astronomy North)</figcaption>
                    </figure>
                </Col>
            </Row>

            <h2 className="display-4">Viewing the northern lights</h2>
            <p>
                The closer you are to the North Magnetic Pole, the better your chances of being able to admire the northern lights. That said, they can also be seen in more southerly parts of Canada during periods of intense solar activity.
            </p>
            <p>
                Generally, the aurora appears a few hours after sunset, when the sky is clear, and tends to become more intense around midnight.
                If you are lucky enough to get to see the aurora up north, here's how to get the most out of your <b>observation night</b>.
            </p>
            <h3 className="display-6">Location</h3>
            <ul>
                <li>
                    Choose a location <b>free of light pollution</b>. The bright city lights that light up the sky make low-intensity auroras invisible.
                </li>
            </ul>
            <h3 className="display-6">Viewing conditions</h3>
            <ul>
                <li>
                    Check the <a href="https://astronomynorth.ca/aurora-forecast/" target="blank" rel="noreferrer">aurora forecast</a> to find out whether the northern lights will
                    be visible in the sky near you.
                </li>
                <li>
                    Visit <a href="http://www.spaceweather.gc.ca/index-en.php" target="blank" rel="noreferrer">Space Weather Canada</a> for Canada's forecast of geomagnetic activity,
                    which is responsible for the aurora borealis.
                </li>
                <li>
                    Check the local weather forecast before leaving. <b>Clouds</b>, <b>precipitation</b> and the <b>full Moon</b> make it difficult
                    to see the aurora.
                </li>
                <li>
                    Look all around you, not only north: auroras may appear anywhere in the sky.
                </li>
                <li>
                    Check the <a href="http://www.swpc.noaa.gov/products/aurora-30-minute-forecast" target="blank" rel="noreferrer">position of the <b>auroral oval</b></a> in real time.
                    Be sure to bundle up—it gets cold at night!
                </li>
                <li>Remember to bring your camera if you want to try to <a href="/learning/photographing-aurora">photograph the aurora</a>.</li>
            </ul>
            <br />
            <h3 className="display-6">Explore Further</h3>
            <ul>
                <li>
                    <a href="/learning/photographing-aurora">Photographing the aurora</a>
                </li>
                <li>
                    <a href="/learning/colours-of-northern-lights">The colours of the northern lights</a>
                </li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
        </Container>
    );
};

export const TopicPhotographingAurora = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
            <h1 className="display-2 pb-3">
                Photographing the aurora
            </h1>
            <p>
                The northern lights are a spectacular display of light. If you get the opportunity
                to <a href="/learning/aurora-viewing-tips">see them with your own eyes</a>, you might also want to capture them on camera.
            </p>
            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/photographing-aurora.jpg" alt="aurora display" width="100%" className="rounded" />
                        <figcaption>Pro tip: Shoot in clear areas as much as possible, but feel free to try areas where landscape elements in
                            the foreground could lend visual appeal to your photos. (Credits: Canadian Space Agency, University of Calgary,
                            Astronomy North)</figcaption>
                    </figure>
                </Col>
            </Row>

            <h2 className="display-4">Equipment required</h2>
            <p>
                To photograph the northern lights, you will need the following equipment:
            </p>
            <ul>
                <li>
                    a <b>camera</b> that is capable of long exposures (at least 10 seconds)
                </li>
                <li>
                    a sturdy <b>tripod</b>, essential in order to prevent shaking
                </li>
                <li>a cable <b>release</b> or <b>remote</b>, also to prevent shaking</li>
                <li>a wide-angle <b>lens</b> (ideally)</li>
            </ul>
            <h2 className="display-4">Steps</h2>
            <p>
                Below are the <b>three steps</b> to taking great photos of the northern lights.
            </p>
            <h3 className="display-6">1. Find a spot</h3>
            <ul>
                <li>
                    Choose a <b>dark</b> location with a clear view of all horizons.
                </li>
                <li>
                    Using a <b>tripod</b>, angle your camera so your image will include both the sky and the trees, or take an image straight up.
                </li>
            </ul>
            <h3 className="display-6">2. Adjust your settings</h3>
            <ul>
                <li>
                    <b>Exposure</b>: To prevent star trails from appearing in your photo, set your exposure time to between 10 and 25 seconds.
                    Longer exposures will produce brighter images; however, if the aurora is moving quickly, the image will be fuzzy.
                </li>
                <li>
                    <b>Aperture</b>: Set the aperture to the lowest possible configuration (f/2.8, f/3.5 or f/5.6, depending on the lens you are
                    using) to ensure there is as much light as possible entering your camera.
                </li>
            </ul>
            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/photographing-aurora2.jpg" alt="aurora" width="100%" className="rounded" />
                        <figcaption>
                            Photo of the northern lights taken in Yellowknife, NWT. (Credits: Canadian Space Agency, University of Calgary, Astronomy North)
                        </figcaption>
                    </figure>
                </Col>
            </Row>
            <ul>
                <li>
                    <b>ISO</b>: Set your film speed to either 400 ISO or 800 ISO to record light with sufficient detail. Higher film speeds collect more
                    light and colour, but image quality is often grainy.
                </li>
                <li>
                    <b>Focus</b>: Focus your camera on the stars. Set your lens on or near infinity for the stars to come into focus. If you are using a
                    digital camera, take a practice photo of the stars and review the image to check the focus.
                </li>
            </ul>
            <h3 className="display-6">3. Start snapping</h3>
            <p>
                Experiment with different combinations of <b>film speed</b> and <b>exposure length</b>. Moonlight and auroras can change the light
                conditions quickly, so be prepared to adjust these settings at any time.
            </p>
            <p>Have fun!</p>
            <br />
            <h3 className="display-6">Explore Further</h3>
            <ul>
                <li>
                    <a href="/learning/what-are-northern-lights">What are the northern lights?</a>
                </li>
                <li>
                    <a href="/learning/aurora-viewing-tips">Aurora viewing tips</a>
                </li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
        </Container>
    );
};

export const TopicStudyingAurora = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
            <h1 className="display-2 pb-3">
                Studying the aurora in Canada
            </h1>
            <p>
                The North Magnetic Pole is in the Canadian Arctic, a geographic location that gives Canadian scientists a privileged viewpoint
                to study the auroras.
            </p>
            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/studying-aurora.jpg" alt="aurora display" width="100%" className="rounded" />
                        <figcaption>Credits: Canadian Space Agency, University of Calgary, Astronomy North</figcaption>
                    </figure>
                </Col>
            </Row>
            <p>
                Studying Earth's relationship with the Sun helps scientists <b>predict periods of intense solar activity</b> so that we can prepare
                for space weather.
            </p>
            <p>
                Scientists are only beginning to understand phenomena linked to Earth's magnetic field. Research being led by Canadians is helping
                provide <b>a better understanding of the magnetosphere</b>, which protects us from the Sun's lethal radiation.
            </p>
            <p>Here are some examples of current Canadian research:</p>
            <Row className="pb-3">
                <Col xs={12} md={4}>
                    <a href="https://www.asc-csa.gc.ca/eng/satellites/cassiope.asp" target="blank" rel="noreferrer" ><b>ePOP</b></a>
                    <p>
                        Suite of scientific instruments on the CASSIOPE satellite to observe the ionosphere.
                    </p>
                </Col>
                <Col xs={12} md={4}>
                    <a href="https://www.asc-csa.gc.ca/eng/satellites/themis/default.asp" target="blank" rel="noreferrer"><b>THEMIS</b></a>
                    <p>
                        Twenty observatories on the ground and five small satellites carrying electric, magnetic, and particle detectors to study auroras.
                    </p>
                </Col>
                <Col xs={12} md={4}>
                    <a href="https://www.asc-csa.gc.ca/eng/sciences/space-weather.asp" target="blank" rel="noreferrer"><b>Space weather over Canada</b></a>
                    <p>
                        Canadian scientists design and operate instruments to improve understanding of the causes and processes of space weather.
                    </p>
                </Col>
            </Row>
            <p>
                Learn more about the history of Sun-Earth studies in Canada:
            </p>
            <ul>
                <li>
                    <a href="https://www.asc-csa.gc.ca/eng/sciences/magnetic-observatory.asp" target="blank" rel="noreferrer">Canada's first magnetic observatory</a>
                </li>
                <li>
                    <a href="https://www.asc-csa.gc.ca/eng/satellites/alouette.asp" target="blank" rel="noreferrer">Satellites Alouette I and II</a>
                </li>
                <li>
                    <a href="https://www.asc-csa.gc.ca/eng/satellites/isis.asp" target="blank" rel="noreferrer">Satellites ISIS I and II</a>
                </li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
        </Container>
    );
};

export const TopicAuroraMAXSimulator = () => {
    return (
        <Container className="container-learning text-left white-text">
            <div className="back-button">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
            <h1 className="display-2 pb-3">
                AuroraMAX aurora borealis simulator
            </h1>
            <p>
                The AuroraMAX aurora borealis simulator is currently at
                the <a href="http://www.pwnhc.ca/" target="blank" rel="noreferrer">Prince of Wales Northern Heritage Centre</a> in Yellowknife, Northwest Territories.
            </p>
            <p>
                This interactive exhibition lets you learn the following on a big screen:
            </p>
            <ul>
                <li>
                    how the northern lights form in
                    the Earth's upper atmosphere;
                </li>
                <li>
                    how we can observe them on Earth;
                </li>
                <li>
                    the effects of solar activity on Earth.
                </li>
            </ul>
            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/auroramax-simulator.jpg" alt="" width="100%" className="rounded" />
                        <figcaption>
                            A person interacting with the Canadian Space Agency's AuroraMAX aurora borealis simulator. (Credit: Canadian Space Agency)
                        </figcaption>
                    </figure>
                </Col>
            </Row>
            <h2 className="display-4">An interactive experience</h2>
            <p>
                A contact-free system perceives hand movements, allowing the user to interact directly with the simulator in order to:
            </p>
            <ul>
                <li>
                    visit the planets of the solar system;
                </li>
                <li>
                    learn more about the phenomena that produce the auroras;
                </li>
                <li>
                    better understand the effects of solar activity on the Earth.
                </li>
            </ul>
            <p>
                You can also:
            </p>
            <ul>
                <li>
                    draw your own aurora and send it by email as a virtual postcard;
                </li>
                <li>
                    see live images of the Sun;
                </li>
                <li>
                    explore an image and video gallery.
                </li>
            </ul>
            <h2 className="display-4">Admire the auroras without having to travel</h2>
            <Row>
                <Col md={9}>
                    <figure>
                        <img src="/img/learning/aurora.jpg" alt="aurora" width="100%" className="rounded" />
                        <figcaption>Credits: Canadian Space Agency (CSA), University of Calgary, Astronomy North</figcaption>
                    </figure>
                </Col>
            </Row>
            <p>
                If you can't get to the Canadian North, you have two options for admiring the auroras:
            </p>
            <ul>
                <li>
                    You can see the aurora borealis live from Yellowknife on your computer thanks to
                    the <a href="/live"> AuroraMAX virtual observatory</a>.
                </li>
            </ul>
            <div className="back-button pt-5">
                <a href="/learning" ><FontAwesomeIcon size="xs" icon={faAnglesLeft} /> Back to Learning</a>
            </div>
        </Container>
    );
};
