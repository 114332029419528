import React, { useEffect } from 'react';
import { Button, Container, Col, Row } from 'react-bootstrap';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/pro-light-svg-icons';
import { faClapperboardPlay } from '@fortawesome/pro-light-svg-icons';
import { faGraduationCap } from '@fortawesome/pro-light-svg-icons';
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';

import '../styles/homepage.scss';

const Homepage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0';
    script.crossOrigin = 'anonymous';
    script.defer = true;
    script.async = true;
    script.nonce = 'fSjNszYW';
    script.id = 'facebook-sdk-script';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container fluid className='m-0 p-0 w-100 container-homepage'>
      <Row
        className='homepage-banner align-items-center m-0'
        style={{
          minHeight: '90vh',
          backgroundImage: `url("/img/banner2.jpg")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Container>
          <Row>
            <Col>
              {/* <h1>AuroraMAX</h1> */}
              <img
                src='/img/logos/auroramax_logo.svg'
                alt='AuroraMAX logo'
                id='banner-logo'
              />
            </Col>
          </Row>
          <Row className='d-flex pt-4 pb-4'>
            <Col>
              <div style={{ margin: 'auto' }}>
                <h2 className='tagline'>Science is Beautiful</h2>
              </div>
            </Col>
          </Row>
          <Container>
            <Row className='pt-5 pb-5 justify-content-center'>
              <Col>
                <a href='/live'>
                  <Button
                    size='lg'
                    variant='outline-light'
                    className='mx-3 mb-3'
                  >
                    <FontAwesomeIcon
                      icon={faCamera}
                      fixedWidth
                      style={{ marginRight: '10px' }}
                    />
                    Watch live feed
                  </Button>
                </a>
                <a href='/movies'>
                  <Button
                    size='lg'
                    variant='outline-light'
                    className='mx-3 mb-3'
                  >
                    <FontAwesomeIcon
                      icon={faClapperboardPlay}
                      fixedWidth
                      style={{ marginRight: '10px' }}
                    />
                    View movie replays
                  </Button>
                </a>
                <a href='/learning'>
                  <Button
                    size='lg'
                    variant='outline-light'
                    className='mx-3 mb-3'
                  >
                    <FontAwesomeIcon
                      icon={faGraduationCap}
                      fixedWidth
                      style={{ marginRight: '10px' }}
                    />
                    Learning Center
                  </Button>
                </a>
              </Col>
            </Row>
          </Container>
        </Container>
      </Row>
      <Row className='justify-content-center gx-0 homepage-section pb-4 px-5'>
        <Col className='pt-5 pb-3 col-xs-12 col-sm-12 col-md-10 col-xl-8 col-xxl-7'>
          <h1 className='display-4 pb-3 white-text'>What is AuroraMAX?</h1>
          <p className='white-text'>
            AuroraMAX is a Canadian aurora observatory. By means of a{' '}
            <strong>ground camera</strong> in Yellowknife, Northwest
            Territories, you can see the aurora from the comfort of your home.
            The camera turns on automatically{' '}
            <strong>as soon as the Sun sets </strong> in Yellowknife during the
            aurora season. The countdown timer shows how much time is left until
            the broadcast starts.
          </p>
          <p className='white-text'>
            In addition to the camera, AuroraMAX is also a public outreach
            initiative that aims to raise awareness of:
            <ul>
              <li>the northern lights and their beauty;</li>
              <li>the science behind the auroras;</li>
              <li>how solar activity can affect our daily lives.</li>
            </ul>
          </p>
          <p className='white-text'>
            The AuroraMAX project is led by the University of Calgary, and
            supported by our fantastic partners, the Canadian Space Agency,
            Astronomy North, and the City of Yellowknife.
          </p>
          <p className='white-text'>
            The AuroraMAX logo, designed by James Pugsley of Astronomy North,
            symbolizes the cause-and-effect relationship between our star and
            our planet. A prominent green spike represents the changing nature
            of the aurora, set against the powerful source behind the solar
            wind—the Sun.
          </p>
        </Col>
      </Row>

      {/* Live feed section */}
      <Row className='row justify-content-center py-5 px-4 homepage-section-alt-bg gx-0'>
        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 px-4'>
          <h3 className='text-center py-3 display-5 white-text'>Live feed</h3>
          <p className='white-text'>
            AuroraMAX makes it possible to watch the northern lights from the
            comfort of your home. A real-time feed is broadcast live from the
            Canadian North during the aurora season (between August and May).
          </p>
          <p>
            <a href='/live' className='custom-colored-link'>
              <b id='link'>Watch the live feed →</b>
            </a>
          </p>
        </div>
        <div className='d-none d-md-block col-md-6 col-lg-5 col-xl-4'>
          <a href='/live'>
            <img
              alt='Latest live feed'
              src='/img/homepage_livefeed1.png'
              className='img-thumbnail img-responsive img-no-border'
            />
          </a>
        </div>
      </Row>

      {/* Movies section */}
      <Row className='justify-content-center py-5 px-4 gx-0 homepage-section'>
        <div className='d-none d-md-block col-md-6 col-lg-5 col-xl-4'>
          <a href='/movies'>
            <img
              alt='Movie browser screenshot'
              src='/img/homepage_movies1.png'
              className='img-thumbnail img-responsive'
            />
          </a>
        </div>
        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 px-4'>
          <h3 className='text-center py-3 display-5 white-text'>
            Nightly Movie Replays
          </h3>
          <p className='white-text'>
            Every morning, a video of the last night's broadcast is generated so
            you can quickly see what the night was like. You can browse all
            movies generated by the AuroraMAX project, going back to 2010.
          </p>
          <p>
            <a href='/movies' className='custom-colored-link'>
              <b>Browse the movie replays →</b>
            </a>
          </p>
        </div>
      </Row>

      {/* Learning section */}
      <Row className='justify-content-center py-5 px-4 homepage-section-alt-bg gx-0'>
        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 px-4'>
          <h3 className='text-center py-3 display-5 white-text'>
            Learn about the aurora
          </h3>
          <p className='white-text'>
            Using the AuroraMAX Learning Center you learn more about the aurora
            and the science around it, along with tips for viewing and
            photographing it yourself.
          </p>
          <p>
            <a href='/learning' className='custom-colored-link'>
              <b>Check out the Learning Center →</b>
            </a>
          </p>
        </div>
        <div className='d-none d-md-block col-md-6 col-lg-5 col-xl-4'>
          <a href='/learning'>
            <img
              alt='Learning center screenshot'
              src='/img/aurora_borealis_photo.jpg'
              className='img-thumbnail img-responsive'
            />
          </a>
        </div>
      </Row>

      {/* Social media section */}
      <Row className='justify-content-center py-5 px-4 gx-0 homepage-section'>
        <div className='d-none d-md-block col-md-6 col-lg-5 col-xl-4'>
          <TwitterTimelineEmbed
            sourceType='timeline'
            screenName='AuroraMAX'
            tweetLimit='1'
            theme='dark'
            options={{ height: 400 }}
          />
        </div>
        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 px-4'>
          <h3 className='text-center py-3 display-5 white-text'>
            AuroraMAX on social media
          </h3>
          <p className='white-text'>
            Stay up to date with AuroraMAX using Twitter and Facebook. We tweet
            whenever upcoming solar storms are predicted so you can know when to
            tune in to the broadcast.
          </p>
          <a
            className='btn btn-outline-light btn-sm me-3 mb-2'
            target='_blank'
            rel='noreferrer'
            href='https://twitter.com/auroramax'
          >
            View Twitter page
            <FontAwesomeIcon
              icon={faTwitter}
              fixedWidth
              style={{ marginLeft: '5px' }}
            />
          </a>
          <a
            className='btn btn-outline-light btn-sm mb-2'
            target='_blank'
            rel='noreferrer'
            href='https://www.facebook.com/AuroraMAX'
          >
            View Facebook page
            <FontAwesomeIcon
              icon={faFacebook}
              fixedWidth
              style={{ marginLeft: '5px' }}
            />
          </a>
        </div>
      </Row>
    </Container>
  );
};

export default Homepage;
