import React from "react";
import { Outlet } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/pro-solid-svg-icons";

import "../../styles/learning.scss";

export const LearningTopics = () => {
    return (
        <>
            <h1 className="pb-3 display-1 text-center white-text">Learning Center</h1>
            <p className="py-3 white-text">
                The AuroraMAX Learning Center provides information about the science
                behind auroras, tidbits about the AuroraMAX observatory, and tips
                on how to view and photograph the aurora yourself.
            </p>

            <Row>
                <Col xs={12} md={4} className="topic white-text">
                    <a href="/learning/what-are-northern-lights">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> What are the northern lights?</h3>
                    </a>
                    <p>
                        Information about auroras and the role of the Sun in their formation.
                    </p>
                </Col>
                <Col xs={12} md={4} className="topic white-text">
                    <a href="/learning/colours-of-northern-lights">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> The colours of the northern lights</h3>
                    </a>
                    <p>
                        Factors that determine the different colours of the aurora.
                    </p>
                </Col>
                <Col xs={12} md={4} className="topic white-text">
                    <a href="/learning/auroramax-observatory">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> AuroraMAX observatory</h3>
                    </a>
                    <p>
                        Online observatory to watch Canada's northern lights in high definition, either in real time or on video.
                    </p>
                </Col>
                <Col xs={12} md={4} className="topic white-text">
                    <a href="/learning/aurora-viewing-tips">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Aurora viewing tips</h3>
                    </a>
                    <p>
                        Some tips on how to view the norhtern lights in person or even from the comfort of your home.
                    </p>
                </Col>
                <Col xs={12} md={4} className="topic white-text">
                    <a href="/learning/photographing-aurora">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Photographing the aurora</h3>
                    </a>
                    <p>
                        Recommended equipment and settings to take great photos of the norhtern lights.
                    </p>
                </Col>
                <Col xs={12} md={4} className="topic white-text">
                    <a href="/learning/studying-aurora-in-canada">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Studying the aurora in Canada</h3>
                    </a>
                    <p>
                        Canadian research on the ionosphere, the magnetosphere, and the processes of space weather.
                    </p>
                </Col>
                {/* <Col xs={12} md={4} className="topic">
                    <a href="/learning/photos"><h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Photos</h3></a>
                    <p>
                        Auroras photographed with the cameras of the AuroraMAX observatory.
                    </p>
                </Col>
                <Col xs={12} md={4} className="topic">
                    <a href="/learning/videos"><h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> Videos</h3></a>
                    <p>
                        Video archives of the northern lights, including videos captured by the AuroraMAX observatory.
                    </p>
                </Col> */}
                <Col xs={12} md={4} className="topic white-text">
                    <a href="/learning/auroramax-simulator">
                        <h3 className="topic-header"><FontAwesomeIcon icon={faAnglesRight} size="xs" /> AuroraMAX simulator</h3>
                    </a>
                    <p>
                        Interactive exhibition in Yellowknife about the aurora borealis.
                    </p>
                </Col>
            </Row>
        </>
    );
};

const Learning = () => {
    return (
        <Container fluid="sm" className="container-learning white-text pb-5">
            <Outlet />
        </Container>
    );
};

export default Learning;
