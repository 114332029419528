import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import GitInfo from "react-git-info/macro";
import '../styles/footer.scss';

// used to show the git commit hash
const gitInfo = GitInfo();

const Footer = () => {
    return (
        <Container fluid className="footer">
            <Container fluid="sm">
                <Row className="d-flex align-items-center text-center">
                    <Col>
                        <a href="https://www.ucalgary.ca" target="_blank" rel="noreferrer">
                            <img src="/img/logos/uofc_logo.png" width="200px" alt="University of Calgary logo" />
                        </a>
                    </Col>
                    <Col>
                        <a href="https://www.asc-csa.gc.ca/eng/default.asp" target="_blank" rel="noreferrer">
                            <img src="/img/logos/csa_logo.png" width="75px" alt="CSA logo" />
                        </a>
                    </Col>
                    <Col>
                        <a href="https://astronomynorth.ca" target="_blank" rel="noreferrer">
                            <img src="https://astronomynorth.ca/wp-content/uploads/2022/07/ANLOGOWhite2018.png" width="200px" alt="Astronomy North logo" />
                        </a>
                    </Col>
                    <Col>
                        <a href="https://www.yellowknife.ca/en/index.aspx" target="_blank" rel="noreferrer">
                            <img src="/img/logos/yellowknifelogo.png" width="200px" alt="City of Yellowknife logo" />
                        </a>
                    </Col>
                </Row>
                <Row className="text-center pt-4 pb-0">
                    <p style={{ fontSize: "12px", marginBottom: "10px", color: "#575757"}}>
                        Version 1.0-{gitInfo.commit.shortHash}
                    </p>
                </Row>
            </Container>
        </Container>
    );
};

export default React.memo(Footer);
