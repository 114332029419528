import React, { useEffect, useState } from 'react';
import {
  // Alert,
  Container,
  Dropdown,
  Button,
  ButtonGroup,
} from 'react-bootstrap';

const AURORAMAX_SITES = {
  yknf: 'Yellowknife',
};

let timer;

const LiveFeed = () => {
  const [selectedSite, setSelectedSite] = useState('yknf');
  const [resolution, setResolution] = useState(480);

  const updateUrl = (resolution) => {
    const dateNow = Date.now();
    return `https://auroramax.phys.ucalgary.ca/recent/recent_${resolution}p.jpg?${dateNow}`;
  };
  const [liveFeedUrl, setLiveFeedUrl] = useState(updateUrl(resolution));

  useEffect(() => {
    // update the image
    const newUrl = updateUrl(resolution);
    setLiveFeedUrl(newUrl);

    // clear the previous interval and set a new one
    clearInterval(timer);
    timer = setInterval(() => {
      const newUrl = updateUrl(resolution);
      setLiveFeedUrl(newUrl);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [resolution]);

  const blurThis = (e) => {
    e.target.blur();
  };

  const handleSiteSelection = (key, e) => {
    blurThis(e);
    setSelectedSite(key);
  };

  const handleResolutionSelection = (e, value) => {
    blurThis(e);
    setResolution(value);
  };

  return (
    <>
      <Container
        fluid
        className='container-live-feed text-center white-text pb-5'
      >
        {/* Alert */}
        {/*
        <div style={{ paddingBottom: '10px' }}>
          <Alert variant='info'>
            2024-08-09: We are dusting off the AuroraMAX live feed from its
            summer break and getting it ready for a wonderful season of aurora
            watching! We expect the broadcast to start up sometime in the next
            couple weeks
          </Alert>
        </div>
        */}

        {/* Header */}
        <h1 className='pb-3 display-1'>Live Feed</h1>
        <div className='row justify-content-center pb-3 px-4'>
          <div className='col-xs-12 col-sm-10 col-md-8 col-lg-8 col-xl-6 col-xxl-5'>
            <p style={{ textAlign: 'justify' }}>
              The AuroraMAX project provides a real-time live feed of the aurora
              from the Canadian North. Images are updated approximately every 6
              seconds during night hours. Please note that the camera does not
              operate during the summer months (May to August) since the nights
              are too bright to see auroras during this period.
            </p>
          </div>
        </div>

        {/* Site selection */}
        <div style={{ display: 'inline-block', paddingBottom: '10px' }}>
          Location:
          <Dropdown
            onSelect={handleSiteSelection}
            onClick={blurThis}
            onMouseOut={blurThis}
            className='d-inline-block live-feed-location-dropdown'
            style={{ paddingLeft: '10px', paddingRight: '20px' }}
          >
            <Dropdown.Toggle variant='outline-light' style={{ width: '125px' }}>
              <span style={{ float: 'left' }}>
                {AURORAMAX_SITES[selectedSite]}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu variant='light'>
              <Dropdown.Item eventKey='yknf' active={selectedSite === 'yknf'}>
                Yellowknife
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Resolution selection */}
        <div style={{ display: 'inline-block', paddingBottom: '10px' }}>
          Resolution:
          <ButtonGroup style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <Button
              variant='outline-light'
              active={resolution === 480}
              onClick={(e) => handleResolutionSelection(e, 480)}
            >
              480p
            </Button>
            <Button
              variant='outline-light'
              active={resolution === 720}
              onClick={(e) => handleResolutionSelection(e, 720)}
            >
              720p
            </Button>
            <Button
              variant='outline-light'
              active={resolution === 1080}
              onClick={(e) => handleResolutionSelection(e, 1080)}
            >
              1080p
            </Button>
          </ButtonGroup>
        </div>

        {/* Live feed image */}
        <div className='pt-4'>
          <img className='img-fluid' src={liveFeedUrl} alt='latest live feed' />
        </div>

        {/* Info content */}
        <div className='row justify-content-center pb-3 px-4 pt-4'>
          <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-8 col-xxl-6'>
            <h1 className='display-6 pt-3 pb-3'>
              Why are the AuroraMAX images round?
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Imagine that you're lying on your back and you can see everything
              around you. That is what AuroraMAX shows you. AuroraMAX's camera
              is an all-sky imager. It captures a 180-degree field of view,
              which produces circular images that show the entire night sky. The
              circumference of the circle is the horizon all around the camera.
              If you look carefully, the tree tops are often visible in the
              outside portion of the image.
            </p>

            <h1 className='display-6 pt-3 pb-3'>Technical Specifications</h1>
            <ul style={{ textAlign: 'left', display: 'inline-block' }}>
              <li>Location: Yellowknife, NWT</li>
              <li>Longitude: 114°21'W</li>
              <li>Latitude: 62°26'N</li>
              <li>Camera: Colour all-sky imager</li>
              <li>Lens: 180° fisheye</li>
              <li>Aperture: f/4.0</li>
              <li>Exposure time: 4 seconds</li>
              <li>Imaging framerate: 6 seconds</li>
            </ul>
          </div>
        </div>
      </Container>
    </>
  );
};

export default LiveFeed;
