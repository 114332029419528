import axios from 'axios';
import React, { useState, forwardRef, useEffect } from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Container,
  Dropdown,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBackward,
  faCalendarDay,
  faForward,
} from '@fortawesome/pro-regular-svg-icons';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import '../styles/movies.scss';

const AURORAMAX_SITES = {
  yknf: 'Yellowknife',
};
const DEFAULT_SITE = 'yknf';
const DEFAULT_RESOLUTION = 480;
const CALENDAR_MIN_DATE = new Date(2012, 1, 14); // remember, months are one less (this date is February 14th)

const Movies = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSite, setSelectedSite] = useState(DEFAULT_SITE);
  const [resolution, setResolution] = useState(DEFAULT_RESOLUTION);
  const [videoUrl, setVideoUrl] = useState();
  const [videoNotFound, setVideoNotFound] = useState(false);

  // below is how we can get URL search parameters to use in the code
  // e.g. url = https://auroramax.com/movies?date=2021/01/01&site=yellowknife&resolution=480px
  // const [searchParams, setSearchParams] = useSearchParams();
  // const siteName = searchParams.get("site");
  // const date = searchParams.get("date");
  // const res = searchParams.get("resolution");

  let dateString = '';
  if (selectedDate !== 'undefined') {
    dateString =
      selectedDate.getFullYear() +
      '/' +
      (selectedDate.getMonth() + 1).toString().padStart(2, '0') +
      '/' +
      selectedDate.getDate().toString().padStart(2, '0');
  }

  const handleSiteSelection = (key, e) => {
    e.target.blur();
    setSelectedSite(key);
  };

  const moveDate = (e, direction) => {
    e.target.blur();
    if (direction === 'previous') {
      setSelectedDate(new Date(selectedDate.getTime() - 86400000));
    } else if (direction === 'next') {
      setSelectedDate(new Date(selectedDate.getTime() + 86400000));
    } else if (direction === 'today') {
      setSelectedDate(new Date());
    }
  };

  const handleResolutionSelection = (e, value) => {
    e.target.blur();
    setResolution(value);
  };

  useEffect(() => {
    const monthString = (selectedDate.getMonth() + 1)
      .toString()
      .padStart(2, '0');
    const dayString = selectedDate.getDate().toString().padStart(2, '0');
    // const url = `https://data.phys.ucalgary.ca/sort_by_project/AuroraMAX/rt-movies/mp4/${selectedDate.getFullYear()}/${monthString}/${dayString}/auroramaxHD_${selectedDate.getFullYear()}${monthString}${dayString}_${resolution}p.mp4`
    const url = `https://movies.auroramax.com/data/mp4/${selectedDate.getFullYear()}/${monthString}/${dayString}/auroramaxHD_${selectedDate.getFullYear()}${monthString}${dayString}_${resolution}p.mp4`;

    axios
      .head(url)
      .then(() => {
        setVideoUrl(url);
        setVideoNotFound(false);
      })
      .catch((e) => {
        setVideoNotFound(true);
      });
  }, [selectedDate, resolution]);

  const DateInputButton = forwardRef(({ value, onClick }, ref) => (
    <ButtonGroup>
      <Button variant='outline-light' onClick={onClick}>
        <FontAwesomeIcon icon={faCalendarDay} />
      </Button>
      <Button
        variant='outline-light'
        style={{ borderRadius: 0, borderLeft: 'none' }}
        onClick={onClick}
        ref={ref}
      >
        {value}
      </Button>
    </ButtonGroup>
  ));

  function renderMovieSection() {
    if (videoNotFound === false) {
      // video was found, show it
      return (
        <Container fluid className='mt-1 text-center'>
          <div className='row justify-content-center'>
            <div
              className='col'
              style={{ marginTop: '20px', fontSize: '14px' }}
            >
              <video
                src={videoUrl}
                controls={true}
                style={{ maxWidth: '100%' }}
              />
            </div>
          </div>
        </Container>
      );
    } else {
      // video wasn't found, show a helpful alert box
      return (
        <Container fluid className='mt-1 text-center'>
          <div className='row justify-content-center'>
            <div
              className='col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-7 text-start'
              style={{ marginTop: '20px', fontSize: '14px' }}
            >
              <Alert variant='warning'>
                <Alert.Heading style={{ fontSize: '20px' }}>
                  No Video Found. Either the camera recently finished
                  broadcasting and the movie hasn't been generated yet, it was
                  during the summer months and the camera didn't operate, or we
                  were having issues running the camera on the selected day.
                </Alert.Heading>
                <hr />
                <p>
                  Whoops! Looks like there's no movie available for {dateString}
                  .
                </p>
                <p className='mb-0'>
                  Please select a different date using the calendar picker.
                </p>
              </Alert>
            </div>
          </div>
        </Container>
      );
    }
  }

  return (
    <>
      {/* Movie controls section */}
      <Container fluid className='container-movies text-center px-4 pb-5'>
        {/*
        <div style={{ paddingBottom: '10px' }}>
          <Alert variant='info'>
            2024-08-09: We are dusting off the AuroraMAX live feed from its
            summer break and getting it ready for a wonderful season of aurora
            watching! We expect the broadcast to start up sometime in the next
            couple weeks
          </Alert>
        </div>
        */}
        <h1 className='pb-3 display-1 white-text'>Movie Replays</h1>
        <div className='row justify-content-center pb-2 text-start'>
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
            <p style={{ textAlign: 'justify' }} className='white-text'>
              AuroraMAX provides movie replays which are time-lapse videos of
              the broadcast from each night the camera operated. Choose a date
              in the calendar below to see a replay of the broadcast from that
              night. Please note that the camera does not operate during the
              summer months since the nights are too bright to see auroras
              (approximately from May to August).
            </p>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col'>
            <div
              style={{ display: 'inline-block', marginBottom: '10px' }}
              className='white-text'
            >
              Location:
              <Dropdown
                onSelect={handleSiteSelection}
                className='d-inline-block'
                style={{ paddingLeft: '5px', paddingRight: '20px' }}
              >
                <Dropdown.Toggle
                  variant='outline-light'
                  style={{ width: '125px' }}
                >
                  <span style={{ float: 'left' }}>
                    {AURORAMAX_SITES[selectedSite]}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey='yknf'
                    active={selectedSite === 'yknf'}
                  >
                    Yellowknife
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              style={{
                display: 'inline-block',
                marginBottom: '10px',
                zIndex: 2,
              }}
              className='white-text'
            >
              Date:
              <ButtonGroup style={{ paddingLeft: '5px', paddingRight: '20px' }}>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  showPopperArrow={false}
                  dateFormat='yyyy/MM/dd'
                  customInput={<DateInputButton />}
                  minDate={CALENDAR_MIN_DATE}
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  popperModifiers={[
                    {
                      name: 'preventOverflow',
                      options: {
                        rootBoundary: 'viewport',
                        tether: false,
                        altAxis: true,
                      },
                    },
                  ]}
                />
                <Button
                  variant='outline-light'
                  onClick={(e) => moveDate(e, 'previous')}
                >
                  <FontAwesomeIcon icon={faBackward} />
                </Button>
                <Button
                  variant='outline-light'
                  onClick={(e) => moveDate(e, 'next')}
                  disabled={
                    selectedDate.getDate() === new Date().getDate() &&
                    selectedDate.getMonth() === new Date().getMonth() &&
                    selectedDate.getFullYear() === new Date().getFullYear()
                  }
                >
                  <FontAwesomeIcon icon={faForward} />
                </Button>
                <Button
                  variant='outline-light'
                  onClick={(e) => moveDate(e, 'today')}
                >
                  Today
                </Button>
              </ButtonGroup>
            </div>
            <div style={{ display: 'inline-block' }} className='white-text'>
              Resolution:
              <ButtonGroup style={{ paddingLeft: '10px' }}>
                <Button
                  variant='outline-light'
                  active={resolution === 480}
                  onClick={(e) => handleResolutionSelection(e, 480)}
                >
                  480p
                </Button>
                <Button
                  variant='outline-light'
                  active={resolution === 720}
                  onClick={(e) => handleResolutionSelection(e, 720)}
                >
                  720p
                </Button>
                <Button
                  variant='outline-light'
                  active={resolution === 1080}
                  onClick={(e) => handleResolutionSelection(e, 1080)}
                >
                  1080p
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>

        {/* Movie display section */}
        {renderMovieSection()}
      </Container>
    </>
  );
};

export default Movies;
